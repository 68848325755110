'use client'

import { useTheme } from 'next-themes'

import { Icons } from '../icons'
import {
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '../ui/dropdown-menu'

export default function SidebarThemeChanger() {
  const { theme, setTheme } = useTheme()

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>Theme</DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent>
            <DropdownMenuRadioGroup value={theme} onValueChange={setTheme}>
              <DropdownMenuRadioItem value='light'>
                <Icons.sun className='mr-3 size-3.5 text-muted-foreground' />
                Light
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem value='dark'>
                <Icons.moon className='mr-3 size-3.5 text-muted-foreground' />
                Dark
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem value='system'>
                <Icons.monitor className='mr-3 size-3.5 text-muted-foreground' />
                System
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  )
}
