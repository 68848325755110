import(/* webpackMode: "eager" */ "/var/www/autodex/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/autodex/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/autodex/src/components/experiments/components/experiment-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/autodex/src/components/layout/sidebar-custom-trigger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/autodex/src/components/layout/sidebar-experiment-subscriber.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/autodex/src/components/layout/sidebar-theme-changer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/autodex/src/components/search-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/var/www/autodex/src/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuGroup","DropdownMenuItem"] */ "/var/www/autodex/src/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/var/www/autodex/src/components/ui/separator.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/autodex/src/components/ui/sidebar.tsx");
