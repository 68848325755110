'use client'

import { useState } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { MoreHorizontal } from 'lucide-react'

import { cn } from '@/lib/utils'

import ExperimentDeleteAlert from '../experiments/components/experiment-delete-alert'
import ExperimentForm from '../experiments/experiment-form'
import { Icons, priorityColors } from '../icons'
import TooltipComponent from '../tooltip-component'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import {
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
} from '../ui/sidebar'

interface SidebarItemProps {
  experiment: Experiment
}

export default function SidebarItem({ experiment }: SidebarItemProps) {
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const path = usePathname()
  const closeModal = () => setOpenDialog(false)

  const currentPath = path.split('/experiments/')[1]?.split('/')[0]

  return (
    <Dialog onOpenChange={setOpenDialog} open={openDialog}>
      <SidebarMenuItem key={experiment.id}>
        <SidebarMenuButton asChild isActive={experiment.id === currentPath}>
          <Link href={`/experiments/${experiment.id}/configuration`}>
            <TooltipComponent
              message={`priority: ${experiment.priority.replace(/_/g, ' ')}`}
              align='start'
            >
              <div
                aria-hidden
                className={cn(
                  priorityColors[experiment.priority],
                  'size-4 rounded-full p-1'
                )}
              >
                <div className='size-2 rounded-full bg-current' />
              </div>
            </TooltipComponent>
            <span>{experiment.name}</span>
          </Link>
        </SidebarMenuButton>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuAction showOnHover>
              <MoreHorizontal />
              <span className='sr-only'>More</span>
            </SidebarMenuAction>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-48' align='end' side='bottom'>
            <DropdownMenuItem asChild>
              <DialogTrigger className='w-full'>Edit Experiment</DialogTrigger>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link
                href={`/experiments/${experiment.id}/configuration`}
                className='cursor-pointer'
              >
                {/* <Folder className='mr-1 size-3 text-muted-foreground' /> */}
                <span>Configuration</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link
                href={`/experiments/${experiment.id}/data`}
                className='cursor-pointer'
              >
                <span>Data</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link
                href={`/experiments/${experiment.id}/progress`}
                className='cursor-pointer'
              >
                <span>Progress</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => setShowAlertDialog(true)}
              className='cursor-pointer'
            >
              <Icons.trash className='mr-1 size-3 text-destructive' />
              <span>Delete Experiment</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
      <ExperimentDeleteAlert
        experimentId={experiment.id}
        open={showAlertDialog}
        setOpen={setShowAlertDialog}
      />
      <DialogContent className='p-0'>
        <ExperimentForm experiment={experiment} closeModal={closeModal} />
      </DialogContent>
    </Dialog>
  )
}
