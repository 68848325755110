'use client'

import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'
import { SearchSlash } from 'lucide-react'
import Highlighter from 'react-highlight-words'

import { cn, isMacOs } from '@/lib/utils'
import { Button } from '@/components/ui/button'

import { Icons } from './icons'
import { Kbd } from './kbd'
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './ui/command'

export function HighlightQuery({
  text,
  query,
}: {
  text: string
  query: string
}) {
  return (
    <Highlighter
      highlightClassName='bg-transparent text-primary underline'
      searchWords={[query]}
      autoEscape={true}
      textToHighlight={text}
    />
  )
}

export default function SearchBar({
  experiments,
}: {
  experiments: Experiment[] | null
}) {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [query, setQuery] = useState('')
  const [isClient, setIsClient] = useState<boolean>(false)

  useEffect(() => {
    setIsClient(true)
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setIsOpen((isOpen) => !isOpen)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  const handleSelect = useCallback((callback: () => unknown) => {
    setIsOpen(false)
    callback()
  }, [])

  useEffect(() => {
    if (!isOpen) setQuery('')
  }, [isOpen])

  return (
    <>
      <Button
        variant='outline'
        onClick={() => setIsOpen(true)}
        className='relative size-10 rounded-md p-0 lg:h-10 lg:w-[23rem] lg:justify-start lg:px-3 lg:py-2'
      >
        <Icons.search
          className='size-4 text-muted-foreground lg:mr-2'
          aria-hidden
        />
        <span className='mr-10 hidden text-muted-foreground lg:inline-flex'>
          Search experiments...
        </span>
        <span className='sr-only'>Search experiments</span>
        <Kbd
          className='pointer-events-none absolute right-1.5 hidden select-none bg-muted text-[10px] lg:flex'
          abbrTitle={isMacOs() ? 'Command' : 'Control'}
        >
          {isClient && isMacOs() ? '⌘' : 'Ctrl+'}K
        </Kbd>
      </Button>
      <CommandDialog open={isOpen} onOpenChange={setIsOpen}>
        <CommandInput
          placeholder='Search experiments...'
          value={query}
          onValueChange={setQuery}
        />
        <CommandList>
          <CommandEmpty>
            <SearchSlash className='mx-auto size-5 text-muted-foreground' />
            <p className='mt-2 text-muted-foreground'>
              Nothing found for{' '}
              <strong className='break-words font-semibold text-foreground'>
                &lsquo;{query}&rsquo;
              </strong>
              . Please try again.
            </p>
          </CommandEmpty>
          <CommandGroup>
            {experiments?.map((experiment, index) => (
              <CommandItem
                key={experiment.id}
                onSelect={() => {
                  handleSelect(() =>
                    router.push(`/experiments/${experiment.id}`)
                  )
                }}
                className={cn(
                  'group grid',
                  index + 1 !== experiments.length && 'border-b'
                )}
              >
                <div
                  aria-hidden='true'
                  className='font-medium transition-colors group-aria-selected:text-primary'
                >
                  <HighlightQuery text={experiment.name} query={query} />
                </div>

                <div
                  aria-hidden='true'
                  className='mt-1 truncate whitespace-nowrap text-muted-foreground'
                >
                  <HighlightQuery
                    text={experiment.status.replace(/_/g, ' ')}
                    query={query}
                  />
                  <span className='mx-2'>/</span>
                  <HighlightQuery
                    text={experiment.priority.replace(/_/g, ' ')}
                    query={query}
                  />
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  )
}
