'use client'

import { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@/components/ui/dialog'

import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/ui/drawer"

import ExperimentForm, { Status } from '../experiment-form'
import { useMediaQuery } from '@/lib/hooks/use-media-query'

interface ExperimentModalProps {
  experiment?: Experiment
  children?: React.ReactNode
  selectedStatus?: Status
}

export default function ExperimentModal({
  selectedStatus,
  experiment,
  children,
}: ExperimentModalProps) {
  const [open, setOpen] = useState<boolean>(false)
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const closeModal = () => setOpen(false)

  if (isDesktop) {
    return (
      <Dialog onOpenChange={setOpen} open={open}>
        <DialogTrigger asChild>
          {children}
        </DialogTrigger>
        <DialogContent className='p-0'>
          <ExperimentForm
            experiment={experiment}
            closeModal={closeModal}
            selectedStatus={selectedStatus}
          />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        {children}
      </DrawerTrigger>
      <DrawerContent>
        <ExperimentForm
          experiment={experiment}
          closeModal={closeModal}
          selectedStatus={selectedStatus}
        />
      </DrawerContent>
    </Drawer>
  )
}
