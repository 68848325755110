'use client'

import { useEffect, useState } from 'react'

import { createClient } from '@/lib/supabase/client'

import SidebarItem from './sidebar-item'

interface Props {
  experiments: Experiment[] | null
  userId: string
}

export default function SidebarExperimentSubscriber({
  experiments,
  userId,
}: Props) {
  const [currentExperiments, setCurrentExperiments] = useState(experiments)
  const supabase = createClient()

  useEffect(() => {
    const channel = supabase
      .channel('sidebar-experiments-channel')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'experiment',
          filter: `user_id=eq.${userId}`,
        },
        (payload) => {
          if (payload.eventType === 'INSERT') {
            if (currentExperiments) {
              setCurrentExperiments((current) =>
                current
                  ? [...current, payload.new as Experiment]
                  : [payload.new as Experiment]
              )
            }
          } else if (payload.eventType === 'DELETE') {
            setCurrentExperiments(
              (prevExperiments) =>
                prevExperiments?.filter(
                  (experiment) => experiment.id !== payload.old.id
                ) as Experiment[]
            )
          } else if (payload.eventType === 'UPDATE') {
            setCurrentExperiments(
              (currentState) =>
                currentState?.map((experiment) =>
                  experiment.id === payload.old.id ? payload.new : experiment
                ) as Experiment[]
            )
          }
        }
      )
      .on(
        'postgres_changes',
        {
          event: 'DELETE',
          schema: 'public',
          table: 'experiment',
        },
        (payload) => {
          setCurrentExperiments(
            (prevExperiments) =>
              prevExperiments?.filter(
                (experiment) => experiment.id !== payload.old.id
              ) as Experiment[]
          )
        }
      )
      .subscribe()

    return () => {
      supabase.removeChannel(channel)
    }
  }, [supabase, currentExperiments, userId])

  return (
    <>
      {currentExperiments?.length ? (
        currentExperiments?.map((experiment) => (
          <SidebarItem experiment={experiment} key={experiment.id} />
        ))
      ) : (
        <div className='px-2 text-sm text-muted-foreground'>No experiments</div>
      )}
    </>
  )
}
